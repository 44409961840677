<template>
  <v-dialog v-model="open" width="750" max-width="100%" persistent scrollable>
    <v-card
      class="mx-auto"
      :class="{
        'w-agromousquetaire-form-modal': true,
        'w-agromousquetaire-form-modal--mobile': $voicer.isMobile,
      }"
    >
      <v-toolbar flat class="color: #fbde51">
        <v-spacer />
        <v-btn icon small @click.stop="open = false">
          <v-icon color="black">mdi-close-thick</v-icon>
        </v-btn>
      </v-toolbar>
      <v-img
        :src="
          $voicer.isMobile
            ? require('~/assets/images/popupagrom.png')
            : require('~/assets/images/popupagrom.png')
        "
        alt="image emission"
        :max-height="$voicer.isMobile === false ? 320 : undefined"
      />
      <v-card-text
        class="w-agromousquetaire-form-modal__body2"
        :class="{
          'pa-2 title': $voicer.isMobile === false,
          'pa-1 headline': $voicer.isMobile === true,
        }"
      >
        Découvrez les podcasts en direct de la réunion Top 130 <br />
        les 15 et 16 novembre derniers à St Malo !
      </v-card-text>
      <div class="body-2">
        <v-btn
          color="primary"
          href="/universe/5ef0b11b4fc7ef4090cc1433"
          target="_blank"
          rounded
          depressed
          >Découvrez les podcasts
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import localstore from 'store'
export default {
  data() {
    const lastOpen = localstore.get('exc_dialog_last_open') || null
    let open = false
    if (!lastOpen) open = true
    else {
      const now = new Date()
      const lastOpenDate = new Date(lastOpen)
      lastOpenDate.setDate(lastOpenDate.getDate() + 1)
      if (lastOpenDate < now) {
        open = true
      }
    }
    if (open === true) {
      localstore.set('exc_dialog_last_open', new Date())
      this.$store.dispatch('player/closePersistantPlayer')
    }
    return {
      open,
    }
  },
  watch: {
    open(status) {
      if (status === false) {
        this.$store.dispatch('player/openPersistantPlayer')
      }
    },
  },
}
</script>

<style lang="scss">
.w-agromousquetaire-form-modal {
  &--mobile {
    display: flex;
    flex-direction: column;
    .v-card__text {
      flex-grow: 1;
      position: relative;
    }
  }
  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  &__body2 {
    justify-content: center;
    text-align: center;
    align-items: center;
  }
  .v-btn {
    margin-bottom: 15px;
  }
  .body-2 {
    justify-content: center;
    text-align: center;
    align-items: center;
  }
}
</style>
